import { Newsletter as BaseNewsletter } from '@themes/store/frontend/components/newsletter/Newsletter';
import ReCAPTCHA                        from "@frontend/components/google-recaptcha";
import { connect }                      from 'react-redux';
import Link                             from 'next/link';
import ReducedModal 				            from '@frontend/components/basic-components/reduced-modal';
import { PRIVACY_POLICY }               from '@frontend/constants';
import _get                             from 'lodash/get';

import { subscribeNewsletter }  from '@frontend/actions';

import '@frontend/components/newsletter/newsletter.less';

class Newsletter extends BaseNewsletter{
  recaptcha = false;
  state = {
    email: '',
    submitted: false,
    error: '',
    showReCaptcha: false,
    captcha:'',
    storeConfiguration:{},
    privacy: false,
    showModal: false
  }
  closeModal = ()=>{
    this.setState({showModal:false})
  }
  executeRecaptcha = async () =>{
    if (typeof (this.recaptcha) != "undefined" && typeof (this.recaptcha.execute) != "undefined") {
      await this.recaptcha.execute();
    }
  }
  validateFields(){
    const {email,captcha,privacy} = this.state;

    this.setState({error:''});

    if(!captcha.length){
      let error = this.i18n.t('newsletter.recaptchaError');
      this.setState({submitted:false,showReCaptcha:true,error});
      return false;
    }
    if (!privacy){
      let error = this.i18n.t('newsletter.privacyError');
      this.setState({submitted:false,showReCaptcha:false,error});
      return false;
    }
    return true;

  }

  handleCheckBox = () =>{
    this.setState((state)=>({privacy:!state.privacy}));
  }
  subscribeNewsletter = async (ev,invisibleCaptcha=null)=>{
    let { email,captcha } = this.state;
    if(ev){
      ev.preventDefault();
    }
    if ((!captcha || !captcha.length) && invisibleCaptcha){
      captcha = invisibleCaptcha;
    }
    this.setState({showReCaptcha:false});
    if (this.validateFields()){
      let variables = {
        email,
        captcha
      };
      await this.props.subscribeNewsletter(this.context,variables);
      this.sendEmailToRetail(email)
      this.setState({ submitted:true,email:'',showModal:true })
    }

  }
  recaptchaRef=(ref)=>{
    this.recaptcha = ref;
  }
  sendEmailToRetail(email){
    if (typeof(window) != "undefined") {
      (window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function() { rrApi.setEmail(email); });
    }
  }
  render(){
    const { email,
            submitted,
            error,
            showReCaptcha,
            captcha,
            privacy,
            showModal
    }                  = this.state;
    const { isMobile,storeConfiguration,storeConfiguration:{googleRecaptchaKey,googleRecaptchaInvisible},subscriptionResult=null  } = this.props;
    const col          = (isMobile)? "col-12":"col-3";
    const privacyPolicySettings = _get(PRIVACY_POLICY.byStore, storeConfiguration.storeId, _get(PRIVACY_POLICY.byStore, PRIVACY_POLICY.defaultId));

    return (
      <div className="subscribe-container">
        <div className="subscribe-container-inner" ref={ node => { this.setCaptchaRef(node)}}>
          <p className="subscribe-title">{this.i18n.t('newsletter.subscribe')}</p>
          <p className="subscribe-info">{this.i18n.t('newsletter.subscribeInfo')}</p>
          <div className="subscribe-form">
            <input className="subscribe-email" name="email"
                value={email} type="email" id="newsletter" placeholder={this.i18n.t('newsletter.emailPlaceholder')}
                required onChange={this.handleChange} onClick={this.showReCaptcha} />
            {
              (error.length!==0) &&
              <p className="subscribe-email-error">{error}</p>
            }

            {
              showReCaptcha &&

              <ReCAPTCHA className="subscribe-recaptcha"
                      isInvisible={googleRecaptchaInvisible}
                      googleRecaptchaKey={googleRecaptchaKey}
                      onChange={this.saveCaptcha}
                      setCaptchaRef={this.recaptchaRef}
                      invisibleCaptchaAction={googleRecaptchaInvisible ? this.subscribeNewsletter : ()=>{} }
               />
            }
            <div className="subscribe-actions">
              <button onClick={googleRecaptchaInvisible ? this.executeRecaptcha: this.subscribeNewsletter}>{this.i18n.t('newsletter.send')}</button>
            </div>
            <div className="extra-field">
              <input name="privacy" type="checkbox" checked={privacy} onChange={this.handleCheckBox} />
              <label className='label required' onClick={this.handleCheckBox} >
                <span>{this.i18n.t(`newsletter.accept`)}</span>
                <Link {...this.router.get('custom',{slug:privacyPolicySettings.slug})}><a><span>{this.i18n.t('newsletter.privacyLink')}</span></a></Link>
              </label>
            </div>
          </div>
          {
            subscriptionResult && showModal &&
            <ReducedModal className="newsletter-modal" closeModal={this.closeModal}>
              {
                (subscriptionResult && subscriptionResult.ok) &&
                  <span className="subscribe-result">{subscriptionResult.response}</span>
              }
              {
                (subscriptionResult && !subscriptionResult.ok) &&
                  <span className="subscribe-result error">{this.i18n.t('newsletter.subscriptionError')}</span>
              }
            </ReducedModal>
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps({isMobile,storeConfig,newsletter}){
  return { storeConfiguration:storeConfig,isMobile,subscriptionResult: newsletter  }
}
export default connect(mapStateToProps,{subscribeNewsletter})(Newsletter);
